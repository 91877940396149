import Vue from 'vue'
// import dummyData from './dummyData'
Vue.mixin({
  data() {
    return {
    //   dummyData
    }
  },
  computed: {
    loggedIn: {
      get(){
        return true
      },
      set(){

      }
    },
    routes(){
      return this.$router.options.routes.sort((a, b) => parseFloat(a.meta.listOrder) - parseFloat(b.meta.listOrder));
    },
    route(){
      return this.$route
    },
    appTexts() {
        return this.$t('appTexts')
      },
  }
})