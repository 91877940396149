<template>
  <v-container mt-flex fluid>
    <v-carousel 
    :cycle=true
    interval="8000" 
    :show-arrows="false"
    class="hidden-xs-only"
    height="">
    <v-carousel-item
      v-for="(item,i) in appTexts.sliderContent"
      :key="i"
      :src="item.img"
      transition="scroll-x-transition"
    >
    <v-row>
    <v-col cols="4" class="pa-5">
      <h3 :class="item.titleColor" class="fs-2">{{ item.title }}</h3>
      <h1 class="onyx--text ozi-font fs-4">{{ item.subTitle }}</h1>
      <p class="onyx--text fs-1">{{ item.description }}</p>
      <v-btn
        class="ma-2"
        dark
        rounded
        :color="item.color"
        :to="item.btnLink"
      >
      {{ item.btnTitle }}
      </v-btn>
      
      
    </v-col>
    </v-row>
    
    </v-carousel-item>
  </v-carousel>
  <v-container d-flex d-sm-none>
  <v-row 
    >
    <v-col cols="12" class="pa-5">
      <h1 class="onyx--text ozi-font fs-2 text-body-2">{{ appTexts.sliderContent[0].subTitle }}</h1>
      <h3 :class="appTexts.sliderContent[0].titleColor" class="fs-2">{{ appTexts.sliderContent[0].title }}</h3>
      <p class="onyx--text fs-75 text-body-1">{{ appTexts.sliderContent[0].description }}</p><br/>
      <v-btn
        class="ma-2"
        dark
        rounded
        :color="appTexts.sliderContent[0].color"
        :to="appTexts.sliderContent[0].btnLink"
      >
      {{ appTexts.sliderContent[0].btnTitle }}
      </v-btn><br/>
      
    </v-col>
    </v-row>
    </v-container>
  <v-row class="pa-5 bg-grey">
        <v-col
          v-for="card in appTexts.cards"
          :key="card.para"
          :cols="card.flex"
        >
        <v-hover
          v-slot="{ hover }">
          <v-card 
              :to="card.link" 
              :elevation="hover ? 16 : 4"
              :class="{ 'on-hover': hover }"
              class="rounded-lg "
              height="330px">
            <v-img
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.2)"
              height="200px"
            >
              <v-card-title class="ozi-font" v-text="card.title"></v-card-title>
            </v-img>
            <v-card-text>
              <p class="onyx--text fs-75 text-body-1">{{ card.para }}</p>
            </v-card-text>
            
          </v-card>
        </v-hover>
        </v-col>
      </v-row>
      <v-row class="pa-5 hidden-xs-only">
        <v-col cols="6">
          <v-lazy 
          transition="view">
          <v-card
          class="rounded-lg float-right"
          elevation=5
          height="408px"
          max-width="75%"
          
          >
          <v-img
            src="/imgs/partiesImage.jpg"
            class="white--text align-end"
            gradient="to bottom, rgba(236,0,140,0), rgba(236,0,140,.2)"
            height="408px"
            >

          </v-img>
          </v-card>
          </v-lazy>
        </v-col>

        

        <v-col cols="6" class="pa-5">
          <h1 class="onyx--text ozi-font fs-2">{{ appTexts.parties.title }}</h1>
          <h3 :class="appTexts.parties.titleColor" class="fs-2">{{ appTexts.parties.subTitle }}</h3><br/>
          <p class="onyx--text fs-75">{{ appTexts.parties.p1 }}</p><br/>
          <p class="onyx--text fs-75">{{ appTexts.parties.p2 }}</p><br/>
          <v-btn
            class="ma-2"
            dark
            rounded
            :color="appTexts.parties.titleColor"
            :to="appTexts.parties.btnLink"
          >
          {{ appTexts.parties.btnTitle }}
          </v-btn><br/>
          
        </v-col>
        
      </v-row>
      <v-row class="pa-5 d-flex d-sm-none">
        <v-col cols="12">
          <v-lazy 
          transition="view">
          <v-card
          class="ma-auto"
          elevation=5
          max-width="100%"
          
          >
          <v-img
            src="/imgs/partiesImage.jpg"
            class="white--text align-end"
            gradient="to bottom, rgba(236,0,140,0), rgba(236,0,140,.2)"
            height="200"
            >

          </v-img>
          </v-card>
          </v-lazy>
        </v-col>
        <v-col cols="6" class="pa-5">
          <h1 class="onyx--text ozi-font">{{ appTexts.parties.title }}<span :class="appTexts.parties.titleColor">&nbsp;{{ appTexts.parties.subTitle }}</span></h1><br/>
          <p class="onyx--text fs-75">{{ appTexts.parties.p1 }}</p><br/>
          <p class="onyx--text fs-75">{{ appTexts.parties.p2 }}</p><br/>
          <v-btn
            class="ma-2"
            dark
            rounded
            :color="appTexts.parties.titleColor"
            :to="appTexts.parties.btnLink"
          >
          {{ appTexts.parties.btnTitle }}
          </v-btn><br/>
          
        </v-col>
        
      </v-row>
  
  </v-container>
</template>

<script>



export default {
  methods: {

    
      
  },

  computed: {

    
  },
}
</script>
<style>

.view-enter-active {
  animation-duration: 1.5s;
  animation-name: slideIn;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-delay: 1s ease-in;
}

@keyframes slideIn {
  from {
    opacity: 0;
    margin-left:-100%;
  }
  
  to {
    opacity: 1;
    margin-left:0%;
  }
}

.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#FFF, 0.8)
}
</style>