import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        lightCyanAcro: '#6EC1E4',
        customGreen: '#BDCC2A',
        customPink: '#F073AB',
        headerGreen: '#00D9A6',
        primary: '#ee44aa',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        sage: "#87BCBF",
        rust: "#D97D54",
        drab: "#324755",
        ice: "#F0F3F4",
        fossil: "#C8D1D3",
        sand: "#B9B0A2",
        onyx: "#1B1C20",
        slate: "#6E8CA0",
        snow: "#FFFFFF",
        magenta: "EC008C",
        cyan: "00AEEF"
      },
    },
  },
});
