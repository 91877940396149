<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
      elevate-on-scroll
      dense
      fixed
      height="80"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Dance Mouse Logo"
          class="shrink mr-2"
          contain
          src="./assets/imgs/logo_dmdance.png"
          transition="scale-transition"
          width="150"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        color="customGreen"
        text
        v-for="items in appTexts.navMenu"
        :key="items.title"
        :to="items.link"
        class="d-none d-lg-flex d-md-flex "
      >
        <div class="onyx--text caption fs-75 text-sm-body-2 text-body-1">{{ items.title }}</div>
      </v-btn>
      
      <div class="text-center d-flex d-sm-flex d-md-none">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              dark
              v-bind="attrs"
              v-on="on"
              class="onyx--text"
            >
              mdi-menu
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              v-for="items in appTexts.navMenu"
              :key="items.title"
              :to="items.link"
            >
              <v-list-item-title class="onyx--text fs-75">{{ items.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      
    </v-app-bar>

    <v-main>
      <router-view transition="slide-x-transition" />
      
    </v-main>
    <v-footer
    color="onyx lighten-1"
    padless
    >
    <cookie-law theme="dark-lime">
      <div slot="message">
        This website uses cookies to ensure the best user experience <router-link to="/cookies-policy">More info</router-link>
      </div>
    </cookie-law>
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          v-for="items in appTexts.footerMenu"
          :key="items.title"
          :to="items.link"
          color="white"
          text
          rounded
          class="my-2"
        >
          {{ items.title }}
        </v-btn>
        <v-col
          class="onyx lighten-2 py-4 text-center white--text"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>DM Dance - Pretoria East</strong>
        </v-col>
      </v-row>
  </v-footer>
  </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',
  
  data: () => ({
    
  }),
  components: { 
    CookieLaw 
    },
};
</script>

<style>
.ozi-font {
    font-family: "OzHandicraftBT-Roman", Helvetica, sans-serif! important;
    letter-spacing: 0.05em;
}
.h12 {
  font-size: 5rem!important;
}

@font-face {
  font-family: "OzHandicraftBT-Roman";

  src: url("./assets/font/fonts/OzHandicraftBT.woff") format("woff"), url("./assets/font/fonts/OzHandicraftBT.woff2") format("woff2"); 
  font-weight: normal;
  font-style: normal;
}

.fs-2 {
    font-size: 3em;
  }
.fs-75 {
    font-size: 0.75em !important;
  }
.fs-10 {
    font-size: 1em !important;
  }
.fs-125 {
    font-size: 1.25em !important;
  }
.fs-4 {
    font-size: 4em !important;
  }
.plr-0 {
  padding: 0 !important;
}
.bg-grey {
  background-color: rgba(0, 0, 0, 0.05);
}

.v-card.on-hover.theme--dark {
  transition: background-color 0.5s ease;
  background-color: rgba(#FFF, 0.8)
}
  .bg-gradient {
      background-image: linear-gradient(
  135deg
  ,#2afca1 0%,#0c71c3 67%)!important;
}
.bg2-gradient {
    background-image: linear-gradient(135deg,#ff75f3 0%,#0c71c3 67%)!important;
}
#bg3-gradient {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+Cjxzdmcgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDE5MjAgNTAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zOnNlcmlmPSJodHRwOi8vd3d3LnNlcmlmLmNvbS8iIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MjsiPgogICAgPHBhdGggZD0iTTE5MjkuMjksLTRMMTk3MCwtNEwxOTcwLDUwNUwtMjAsNTA1TC0yMCwtNEwtMTEuMjkzLC00QzI5MC4wNjMsMTE4LjEwOSAyNzMuMzc3LDI0OS42NjMgODAwLjI4NCwyNjcuNDA2QzEzOTMsMjg3LjM2NiAxNzI1LjAyLDE1Mi4wMDkgMTkyOS4yOSwtNFoiIHN0eWxlPSJmaWxsOndoaXRlOyIvPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMC45OTc0MDIsMCwwLDEuMDcyNjYsLTAuMDUxOTU4MywtMzYuNjkxNykiPgogICAgICAgIDxwYXRoIGQ9Ik0xOTI5LjI5LC00TDE5NzAsLTRMMTk3MCw1MDVMLTIwLDUwNUwtMjAsLTRMLTExLjI5MywtNEMyOTAuMDYzLDExOC4xMDkgMjczLjM3NywyNDkuNjYzIDgwMC4yODQsMjY3LjQwNkMxMzkzLDI4Ny4zNjYgMTcyNS4wMiwxNTIuMDA5IDE5MjkuMjksLTRaIiBzdHlsZT0iZmlsbDp3aGl0ZTtmaWxsLW9wYWNpdHk6MC41OyIvPgogICAgPC9nPgogICAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMS4wNDYzOSwwLDAsMS4xMzkyNiwtOTYuNTU5MiwtNzAuMzI1OCkiPgogICAgICAgIDxwYXRoIGQ9Ik0xOTI5LjI5LC00TDE5NzAsLTRMMTk3MCw1MDVMLTIwLDUwNUwtMjAsLTRMLTExLjI5MywtNEMyOTAuMDYzLDExOC4xMDkgMjczLjM3NywyNDkuNjYzIDgwMC4yODQsMjY3LjQwNkMxMzkzLDI4Ny4zNjYgMTcyNS4wMiwxNTIuMDA5IDE5MjkuMjksLTRaIiBzdHlsZT0iZmlsbDp3aGl0ZTtmaWxsLW9wYWNpdHk6MC4zOyIvPgogICAgPC9nPgo8L3N2Zz4K');
    background-size: 100%;
    bottom: 0;
    height: 125px;
    z-index: 1;
    margin-bottom: -1px;
}
.v-parallax__content {
    padding: 0px !important;
  }
.ts-2 {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}
</style>